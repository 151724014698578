<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Proyectos - Consumo de horas</h4>
            <div class="small text-muted">Gestión del consumo de horas</div>
          </b-col>
        </b-row>
      </b-card>
      
      <b-row>
        <b-col sm="6">
          <b-card header-tag="header" footer-tag="footer">
            <FullCalendar defaultView="dayGridMonth" 
                          :plugins="calendarPlugins"                           
                          :locale="calendarOptions.locale"
                          :weekends="true" 
                          :events="calendarOptions.event"
                          :selectable="true" 
                          @dateClick="dateClick "/>
          </b-card>                            
        </b-col>
        <b-col sm="6">
          <b-card header-tag="header" footer-tag="footer">
            <b-row class="mb-3">
              <b-col md="6">
                <h2>{{moment(this.crud.form.date).format('DD')}} de 
                    {{moment(this.crud.form.date).format('MMMM')}} de 
                    {{moment(this.crud.form.date).format('YYYY')}}</h2>
              </b-col>
              <b-col md="6">
                <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Horas">
                  <i class="fa fa-plus"></i> Agregar
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">                        
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="table.items"
                        :fields="table.fields"
                        v-if="table.items.length">

                    <template v-slot:cell(assignment)="data">
                      <div v-if="data.item.projects_id">
                        <b>Proyecto:</b> {{ data.item.projects.name }} <br>  
                        <b>Cliente:</b> {{ data.item.projects.customer.name}}
                      </div>                      
                      <div v-else>                        
                        {{ data.item.activity.name }}
                      </div>                                                                  
                    </template>

                    <template v-slot:cell(description)="data">
                      <div v-if="data.item.description.length>50" :title="data.item.description">
                        {{ data.item.description.substring(0,50) }} ...
                      </div>
                      <div v-else>
                        {{ data.item.description }}
                      </div>
                    </template>

                    <template v-slot:cell(hours)="data">
                      <b>{{ data.item.hours }} hs</b>
                    </template>

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>                
              </b-col>
            </b-row>            
            <b-row class="mt-3" align-h="center" v-if="table.items.length">
              <b-col md="8" center>
                <b-table-simple hover small caption-top responsive>
                  <b-thead head-variant="dark">
                    <b-tr>                      
                      <b-th colspan="2" class="text-center">TOTALES</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-th>Proyectos</b-th>
                      <b-td class="text-right">{{this.totalProjects.toFixed(2)}} Hs</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>Actividades</b-th>
                      <b-td class="text-right">{{this.totalActivity.toFixed(2)}} Hs</b-td>
                    </b-tr>                    
                  </b-tbody>       
                  <b-tfoot>
                    <b-tr>
                      <b-td colspan="7" variant="secondary" class="text-right">
                        Total Trabajado: <b>{{(this.totalActivity + this.totalProjects).toFixed(2)}} Hs</b>
                      </b-td>
                    </b-tr>
                  </b-tfoot>                             
                </b-table-simple>               
              </b-col>              
            </b-row>
          </b-card>
          
          <b-card header-tag="header" footer-tag="footer">
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>
            </b-row>
          </b-card>          
        </b-col>
      </b-row>      
      
      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Tipo" class="text-center">
              <b-form-radio-group
                v-model="crud.form.type.selected"
                :options="crud.form.type.options"
                name="radios-type"
                size="md"
              ></b-form-radio-group>
            </b-form-group>            
          </b-col>

          <b-col md="8">            
            <FindObject type="project" 
                        @select-object="loadProjects($event)" 
                        :valueID="crud.form.projects_id" 
                        render="search"
                        v-show="crud.form.type.selected == 'project'"
                        :where="conditionProjectActive"/>
          
            <FindObject type="activity" 
                        @select-object="loadActivity($event)" 
                        :valueID="crud.form.activity_id" 
                        render="search"
                        v-show="crud.form.type.selected == 'activity'"/>
          </b-col>            

          <b-col md="4">
            <b-form-group label="Horas">
              <b-form-input type="number"
                            size="sm"
                            v-model="crud.form.hours"   
                            step='0.01'                         
                            placeholder="Ingresar una hora">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Descripción">
              <b-form-textarea                                
                v-model="crud.form.description"
                size="sm"
                placeholder="Ingresa la descripción de la tarea"
              ></b-form-textarea>
            </b-form-group>          
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Profiles from '@/config/profiles'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment' 
  import FindObject from '@/components/inc/find/findObject'

  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'    
  import esLocale from '@fullcalendar/core/locales/es';
  import interactionPlugin from '@fullcalendar/interaction';

  export default {
    components: {          
      FindObject,
      FullCalendar
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.PROYECTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudHours',
          elements: {}
        }, 
        calendarPlugins: [ dayGridPlugin, interactionPlugin ],        
        calendarOptions: {
          locale: esLocale,
          event: []
        },
        table : {
          items: [],
          fields: [            
            {key: 'assignment', label: 'Asignación', sortable: true},            
            {key: 'description', label: 'Detalle'},            
            {key: 'hours', label: 'Horas', class:"text-center", sortable: true},
            {key: 'f_action', label:''},
          ],        
        },  
        crud: {
          form: {
            date: moment().format('YYYY-MM-DD'),            
            type: {
              selected: 'project',
              options: [
                { text: 'Proyectos', value: 'project' },
                { text: 'Actividades', value: 'activity' }
              ]
            },                  
            projects_id: 0,
            projects: null,
            activity_id: 0,
            activity: null,
            hours: 0,
            description: '',            
            staff_id: Helper.getStaffId()
          }
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        }           
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted () {
      if(!this.isEmployee) {
        this.$router.push({ name: 'Error403' })
      }

      moment.locale('es');
      this.load()      
    },      
    computed: {
      totalActivity: function() {
        var total = 0
        this.table.items.forEach(element => {
          if(element.activity_id) {
            total = total + parseFloat(element.hours)
          }          
        }) 
        return total             
      }, 
      totalProjects: function() {
        var total = 0
        this.table.items.forEach(element => {
          if(element.projects_id) {
            total = total + parseFloat(element.hours)
          }          
        }) 
        return total      
      },
      isEmployee(){        
        if(Helper.getEmployee()) {
          return true
        } else {
          return false
        }
      },
      conditionProjectActive(){
        return [{field: 'status', condition: 'active'}];
      },                   
    },
    methods: {
      dateClick(date) {           
        this.crud.form.date = date.dateStr
        this.getHours()        
      },
      load(){
        this.loadCalendar()
        this.getHours()
      },
      loadCalendar() {   
        let loader = this.$loading.show();

        this.calendarOptions.event = []

        var data = {          
          staff_id: Helper.getStaffId()          
        }
        var result = serviceAPI.obtenerHorasByEmployee(data)

        result.then((response) => {                    
          var data = response.data

          data.projects.forEach(element => {
            var event = {}

            event = {
              title: 'Proyectos: ' + element.hours + ' hs',
              start: element.date,
              end: element.date,
              color: "#bae6c9",
            }            
            this.calendarOptions.event.push(event)            
          }); 
          
          data.activity.forEach(element => {
            var event = {}

            event = {
              title: 'Actividades: ' + element.hours + ' hs',
              start: element.date,
              end: element.date,
              color: "#ffeeba",
            }            
            this.calendarOptions.event.push(event)            
          }); 

          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });                     
      },
      getHours() {
        let loader = this.$loading.show();
        var data = {
          staff_id: Helper.getStaffId(),
          date: this.crud.form.date
        }              
        var result = serviceAPI.obtenerHorasByDia(data)

        result.then((response) => {                    
          var data = response.data
          
          data.forEach(element => {
            if(element.projects_id) {
              element["_cellVariants"] = { assignment: 'success' }
            }
            else {
              element["_cellVariants"] = { assignment: 'warning' }
            }
            this.table.items.push(element)
          });
          
          this.table.items = data   
                              
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.projects_id = 0          
        this.crud.form.projects = null
        this.crud.form.activity_id = 0
        this.crud.form.activity = null
        this.crud.form.hours = 0
        this.crud.form.description = ''
        this.crud.form.type.selected = 'project'
                    
        this.modal.form.title = "Agregar Horas"
        this.modal.form.active = true
      },
      edit(item) {       
        this.crud.form.id = item.id

        if(item.projects_id){
          this.crud.form.projects_id = item.projects.id         
          this.crud.form.projects = item.projects
          this.crud.form.type.selected = 'project'
        } else {
          this.crud.form.activity_id = item.activity.id
          this.crud.form.activity = item.activity
          this.crud.form.type.selected = 'activity'
        }
        
        this.crud.form.hours = item.hours
        this.crud.form.description = item.description

        this.modal.form.title = "Editar Horas"
        this.modal.form.active = true
      },   
      remove(item) {
        this.crud.form.id = item.id
        if(item.projects_id) {
          this.crud.form.name = item.projects.name        
        } else {
          this.crud.form.name = item.activity.name        
        }

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Hora',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarHora(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        if (this.crud.form.id) {
          var result = serviceAPI.editarHora(this.crud.form);
        } else {
          var result = serviceAPI.agregarHora(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      loadProjects (object) {        
        if(object){
          this.crud.form.projects = object
          this.crud.form.projects_id = object.id        
          this.crud.form.activity = null
          this.crud.form.activity_id = 0          
        } else {
          this.crud.form.projects = null
          this.crud.form.projects_id = 0
        }
      },   
      loadActivity (object) {        
        if(object){
          this.crud.form.activity = object
          this.crud.form.activity_id = object.id                  
          this.crud.form.projects = null
          this.crud.form.projects_id = 0          
        } else {
          this.crud.form.activity = null
          this.crud.form.activity_id = 0
        }
      },             
    }
  }
</script>
<style>
  .project-detail-hours-badge {
    font-size: 13px;
  }
</style>